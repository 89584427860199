<template>
  <!-- NAME[epic=报表] 品牌统计 -->
  <div class="orderTest-container">
    <el-form
      ref="form"
      :model="form"
      inline
      label-position="right"
      label-suffix=":"
      label-width="120px"
    >
      <el-form-item label="" prop="depart_id">
        <el-select
          v-model="form.depart_id"
          clearable
          multiple
          placeholder="请选择部门"
        >
          <el-option
            v-for="item in departSelect"
            :key="item.id"
            :label="item.text"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="" prop="staff_id">
        <el-select
          v-model="form.staff_id"
          multiple
          clearable
          placeholder="请选择业务员"
        >
          <el-option
            v-for="(i, idx) in userSelect"
            :key="idx"
            :value="i.id"
            :label="i.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="" prop="class_id">
        <el-select
          v-model="form.class_id"
          multiple
          clearable
          placeholder="请选择品类"
        >
          <el-option
            v-for="item in classSelect"
            :key="item.id"
            :label="item.class_name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="" prop="statistics_type">
        <!-- 1仅销售单 2已结清销售单 3包含未到货预订单 -->
        <el-select v-model="form.statistics_type" clearable placeholder="">
          <el-option :value="1" label="仅销售单"></el-option>
          <el-option :value="2" label="已结清销售单"></el-option>
          <el-option :value="3" label="包含未到货预订单"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-date-picker
          v-model="time"
          type="datetimerange"
          :default-time="['00:00:00']"
          range-separator="-"
          value-format="yyyy-MM-dd HH:mm:ss"
          format="yyyy-MM-dd HH:mm:ss"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-button type="primary" @click="handleQuery">查询</el-button>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-button @click="handleExport">导出</el-button>
      </el-form-item>
    </el-form>
    <el-table
      v-loading="loading"
      border
      stripe
      :data="list"
      show-summary
      :summary-method="summaryMethon"
    >
      <el-table-column align="center" type="index" width="50">
        <template slot="header">序号</template>
      </el-table-column>
      <el-table-column
        v-for="(item, tableIndex) in columns"
        :key="tableIndex"
        :prop="item.prop"
        :label="item.label"
        :width="item.width"
        align="center"
      ></el-table-column>
    </el-table>
    <el-pagination
      :current-page="form.pageNo"
      :layout="layout"
      :page-size="form.pageSize"
      :total="total"
      background
      style="text-align: center"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    ></el-pagination>
  </div>
</template>
<script>
  import { downloadFile, postAction } from '@/api/Employee'
  import { getStaffList } from '@/api/setPrice'
  import { classList } from '@/api/selectList'
  export default {
    name: 'BandManage',
    components: {},
    data() {
      return {
        form: {
          pageNo: 1,
          pageSize: 10,
          statistics_type: 1,
        },
        time: [],
        departSelect: [],
        classSelect: [],
        userSelect: [],
        loading: false,
        list: [],
        zong: {},
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        columns: [
          {
            order: 2,
            label: '品牌名称',
            prop: 'brand_name',
            width: '',
          },
          {
            order: 3,
            label: '销售金额',
            prop: 'sale_money',
            width: '',
          },
          {
            order: 4,
            label: '退货金额',
            prop: 'return_money',
            width: '',
          },
          {
            order: 5,
            label: '毛利',
            prop: 'profit_money',
            width: '',
          },
          {
            order: 6,
            label: '毛利率',
            prop: 'rate',
            width: '',
          },
          {
            order: 7,
            label: '占比',
            prop: 'bfb',
            width: '',
          },
        ],
      }
    },
    computed: {},
    watch: {
      time(v) {
        if (v) {
          this.form.start_time = v[0]
          this.form.end_time = v[1]
        } else {
          this.form.start_time = ''
          this.form.end_time = ''
        }
      },
    },
    created() {},
    mounted() {
      this.initSelect()
      this.fetchData()
    },
    methods: {
      async initSelect() {
        //部门列表
        postAction('/baseAdmin/common/depart-list', {}).then((res) => {
          this.departSelect = res.data.list
        })
        // 获取业务员下拉
        getStaffList().then((r) => {
          this.userSelect = r.data
        })
        classList().then((r) => {
          this.classSelect = r.data
        })
      },
      async fetchData() {
        this.loading = true
        let form = { ...this.form }
        form.depart_id = form.depart_id && form.depart_id.join()
        form.staff_id = form.staff_id && form.staff_id.join()
        form.class_id = form.class_id && form.class_id.join()
        try {
          let res = await postAction(
            '/saleAdmin/sale-order-form/bill-brand',
            form
          )
          this.list = res.data.list
          this.zong = res.data.zong
          this.total = Number(res.totalCount) || 0
          this.loading = false
        } catch (err) {
          console.log('接口调用错误', err)
          this.loading = false
        }
      },
      handleQuery() {
        this.form.pageNo = 1
        this.fetchData()
      },
      handleExport() {
        let form = { ...this.form }
        form.depart_id = form.depart_id && form.depart_id.join()
        form.staff_id = form.staff_id && form.staff_id.join()
        form.class_id = form.class_id && form.class_id.join()
        downloadFile(
          '/saleAdmin/sale-order-form/bill-brand-export',
          '品牌统计.xlsx',
          form
        )
      },
      summaryMethon({ columns, data }) {
        let sums = ['总计']
        columns.forEach((column, index) => {
          if (column.label == '销售金额') {
            sums[index] = this.zong.sale
          }
          if (column.label == '退货金额') {
            sums[index] = this.zong.return
          }
          if (column.label == '毛利率') {
            sums[index] = this.zong.rate
          }
          if (column.label == '毛利') {
            sums[index] = this.zong.profit
          }
          if (column.label == '占比') {
            sums[index] = this.zong.bfb
          }
        })
        return sums
      },
      handleCurrentChange(val) {
        this.form.pageNo = val
        this.fetchData()
      },
      handleSizeChange(val) {
        this.form.pageSize = val
        this.fetchData()
      },
    },
  }
</script>
<style lang="scss" scoped></style>
